import React, { useState, useEffect } from 'react';
import { Navbar, Container, Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import axios from 'axios';

const Home = () => {
  const [dataJadwal, setDataJadwal] = useState([]);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [subuh, setSubuh] = useState('');
  const [dzuhur, setDzuhur] = useState('');
  const [ashar, setAshar] = useState('');
  const [maghrib, setMaghrib] = useState('');
  const [isha, setIsha] = useState('');
  const [city, setCity] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [show, setShow] = useState(false);

  const today = new Date();
  const day = today.getDate();
  const dayFix = String(day).padStart(2, '0');
  const month = today.getMonth() + 1; // Bulan dimulai dari 0 (Januari), maka tambahkan 1
  const monthString = String(month).padStart(2, '0');
  const year = today.getFullYear();
  const hariIni = `${dayFix}-${monthString}-${year}`;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const getLokasi = async () => {
      try {
        const response = await axios.get('https://api.codingrame.com/onelokasi');
        const lat = response.data[0].lat;
        const lon = response.data[0].long;
        setLatitude(lat);
        setLongitude(lon);

        fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`)
          .then(response => response.json())
          .then(data => {
            const cityName = data.address?.city || data.address?.town || data.address?.village || data.address?.hamlet || 'Unknown';
            setCity(cityName);
          })
          .catch(error => {
            setErrorMessage('Error fetching city: ' + error.message);
          });
      } catch (error) {
        console.log(error);
      }
    };

    getLokasi();
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      const getJadwalSholat = async () => {
        try {
          const response = await axios.get(`https://api.aladhan.com/v1/calendar/${year}/${month}?latitude=${latitude}&longitude=${longitude}&method=2`);
          setDataJadwal(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };

      getJadwalSholat();
    }
  }, [latitude, longitude, year, month]);

  useEffect(() => {
    if (dataJadwal.length > 0) {
      const todayJadwal = dataJadwal.find(data => data.date.gregorian.date === hariIni);

      if (todayJadwal) {
        setSubuh(todayJadwal.timings.Fajr);
        setDzuhur(todayJadwal.timings.Dhuhr);
        setAshar(todayJadwal.timings.Asr);
        setMaghrib(todayJadwal.timings.Maghrib);
        setIsha(todayJadwal.timings.Isha);

        const sendData = async () => {
          try {
            await axios.post('https://api.codingrame.com/jadwalsholat', {
              fajr: todayJadwal.timings.Fajr.split(' ')[0],
              dhuhr: todayJadwal.timings.Dhuhr.split(' ')[0],
              asr: todayJadwal.timings.Asr.split(' ')[0],
              maghrib: todayJadwal.timings.Maghrib.split(' ')[0],
              isha: todayJadwal.timings.Isha.split(' ')[0],
              tanggal: hariIni,
            });
          } catch (error) {
            console.error('Error uploading', error);
          }
        };

        sendData();
      }
    }
  }, [dataJadwal, hariIni]);

  const postJadwalSholat = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://api.codingrame.com/lokasi`, {
        lat: lat,
        long: long,
      });
      if (response.status === 201) {
        alert("Data berhasil ditambahkan");
      } else {
        alert("Data gagal ditambahkan");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar className='bg-info'>
        <h1 className='text-center w-100'>Jadwal Sholat {city}</h1>
      </Navbar>

      <br />
      <br />

      <Container>
        <div className="mt-3 mb-3">
          <center>
            <Button variant="primary" className='' onClick={handleShow}>
              Submit Lokasi
            </Button>
          </center>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Isi Lokasi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form>
                    <Form.Group className="mb-3" controlId="latitude">
                      <Form.Label>Latitude</Form.Label>
                      <Form.Control type="text" placeholder="Enter latitude" onChange={(e) => setLat(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="longitude">
                      <Form.Label>Longitude</Form.Label>
                      <Form.Control type="text" placeholder="Enter longitude" onChange={(e) => setLong(e.target.value)} />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={postJadwalSholat}>
                Submit lokasi
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <br />
        <br />
        <Row>
          <Col>
            <Card style={{ width: '12rem' }}>
              <Card.Body className='text-center'>
                <Card.Title><h1>Subuh</h1></Card.Title>
                <Card.Text>
                  <h2>{subuh || 'Loading'}</h2>
                </Card.Text>
                <Card.Subtitle className="mb-2 text-muted">{hariIni}</Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '12rem' }}>
              <Card.Body className='text-center'>
                <Card.Title><h1>Dzuhur</h1></Card.Title>
                <Card.Text>
                  <h2>{dzuhur || 'Loading'}</h2>
                </Card.Text>
                <Card.Subtitle className="mb-2 text-muted">{hariIni}</Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '12rem' }}>
              <Card.Body className='text-center'>
                <Card.Title><h1>Ashar</h1></Card.Title>
                <Card.Text>
                  <h2>{ashar || 'Loading'}</h2>
                </Card.Text>
                <Card.Subtitle className="mb-2 text-muted">{hariIni}</Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '12rem' }}>
              <Card.Body className='text-center'>
                <Card.Title><h1>Maghrib</h1></Card.Title>
                <Card.Text>
                  <h2>{maghrib || 'Loading'}</h2>
                </Card.Text>
                <Card.Subtitle className="mb-2 text-muted">{hariIni}</Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '12rem' }}>
              <Card.Body className='text-center'>
                <Card.Title><h1>Isha</h1></Card.Title>
                <Card.Text>
                  <h2>{isha || 'Loading'}</h2>
                </Card.Text>
                <Card.Subtitle className="mb-2 text-muted">{hariIni}</Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;